#mby {
    margin-bottom: 50px;
}

#w {
    color: gray;
}

.text-kembali {
    padding-left: 90px;
    margin-top: 10px;    
}

.kay {
    color: gray;
}

.verifikasi {
    color: #00C6C8;
}
.bggg{
    background-color:  #00C6C8;
}


.hai{
    margin-top: -35px ;
}
.yo{
    margin-top: 15px;
}
.pro{
    margin-top: -20px;
}
.kon{
    margin-top: -40px;
}
.sya{
    margin-top: -30px;
}
.oke{
    margin-top: -20px;
}
.si{
    margin-top: 5px;
}
.va{
    margin-top: -50px;
}
.ja{
    margin-top: -60px;
}
.pu{
    margin-top: -30px;
}
.hiya{
    margin-top: -40px;
}
.ketentuan{
    margin-top: -10px;
}
.ya{
    margin-top: -10px;
}
.priv{
    margin-top: -60px;
}