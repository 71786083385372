@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  background-color: #e5e5e5;
  overflow-x: hidden !important;
}

/* Games */
.px-cst1 {
  padding-left: 1.9rem;
  padding-right: 1.9rem;
}

.navbar span {
  font-weight: 500;
  font-size: 18px;
}

.content .form-control-lg {
  padding: 0.8rem 1rem;
  font-size: 1rem;
}

.content form {
  padding: 0 1.8rem;
}

.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 5px;
  border-top: unset;
  border-bottom: unset;
  border-radius: 5px !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.owl-carousel .owl-nav button.owl-prev {
  font-size: 30px !important;
  padding-right: 0.5rem !important;
}

.owl-carousel .owl-nav button.owl-next {
  font-size: 30px !important;
  padding-left: 0.5rem !important;
}

.list-game a {
  text-decoration: none;
}

.text-grey {
  color: #454545;
}

@media (max-width: 575.98px) {
  .content.px-5 {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
  }

  .offcanvas {
    width: 100% !important;
  }

  .main-beranda .bg-saldo {
    width: 90% !important;
  }

  .product .card-body {
    font-size: 10px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* Denom Games */
.promo {
  font-size: 10px;
  padding: 1.5px 15px !important;
  border-radius: 0 5px 0 5px;
  background-color: #00c6c8;
}

.gangguan {
  font-size: 10px;
  padding: 1.5px 15px !important;
  border-radius: 0 5px 0 5px;
  background-color: #f0b83a;
}

.align-middle {
  vertical-align: middle;
}

.list-denom .d-flex h6 {
  color: #00c6c8;
}

.card a.card-body {
  text-decoration: none;
}

.main-beranda .bg-saldo {
  top: unset !important;
  width: 43%;
}

.main-beranda .card-body.saldo {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}

.main-beranda .alert-light .btn-close {
  top: 3px;
  padding: 1rem;
}

.main-beranda .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/.7em auto no-repeat;
}

.img-bg-top {
  background-image: url("/public/img/bg-top.svg");
  background-repeat: no-repeat;
}

.main-beranda .navbar-bottom img {
  width: 30px;
}

.main-beranda .navbar-bottom a.card {
  text-decoration: none;
  cursor: pointer;
}

.main-beranda .container.logo {
  padding-left: 1.5rem;
}

.font-8 {
  font-size: 8px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-32 {
  font-size: 32px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hr {
  border-top: 1px dashed grey;
  margin: 0.95rem 0 0.5rem;
}

.hr-line {
  border-top: 1px solid #eeeeee;
  margin: 0.95rem 0 0.5rem;
}

.riwayat .nav-pills .nav-link {
  border-radius: unset;
}

.riwayat .nav-link {
  color: unset;
}

.riwayat .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: unset !important;
  border-bottom: 2px solid #00c6c8;
  color: #00c6c8;
  box-shadow: 0 0 0 transparent !important;
}

.riwayat .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  box-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.075) !important;
}

.riwayat .nav-item button {
  font-size: 16px;
  font-weight: 500;
}

.riwayat .date-time {
  font-size: 12px;
}

.list-denom a:hover {
  color: unset;
}

.filter a {
  color: #00c6c8;
}

.offcanvas {
  width: 45.8%;
  display: block;
  margin: auto;
}


input[type="check"] {
  display: none;
}

.filter .form-check-input:checked {
  background-color: #00c6c8;
  border-color: #00c6c8;
}

.pengaturan .form-check-input:checked {
  background-color: #00c6c8;
  border-color: #00c6c8;
}

.rincian .form-check-input:checked {
  background-color: #00c6c8;
  border-color: #00c6c8;
}

.deleteform .form-check-input:checked {
  background-color: #f0b83a;
  border-color: #f0b83a;
}

.filter .form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.filter .offcanvas-bottom.filter-short {
  height: 230px;
}

.filter .offcanvas-bottom.filter-filter {
  height: 400px;
}

.filter .offcanvas-bottom.filter-sort {
  width: 300px;
}


.filter .offcanvas-bottom.filter-product {
  height: 400px;
}

.filter .offcanvas-bottom.ganti-gudang {
  height: 300px;
}

.rincian .offcanvas-bottom.rincian-product {
  height: 300px;
}

.rincian .offcanvas-bottom.rincian-product {
  width: 760px;
}

.rounded-top.ganti-gudang.offcanvas.offcanvas-bottom.show {
  height: 280px;
}

.rounded-top.rincian-pesanan.offcanvas.offcanvas-bottom.show {
  height: 230px;
}

.rounded-top.detail-product.offcanvas.offcanvas-bottom.show {
  height: 320px;
}

.order .offcanvas-bottom.order-product {
  height: 280px;
}

.order .offcanvas-bottom.order-product {
  width: 760px;
}

.rounded-top.offcanvas.offcanvas-bottom.show {
  height: 280px;
}

.filter .offcanvas-body p.fs-6 {
  color: #00c6c8;
}

.filter .offcanvas .btn-primary {
  background-color: #00c6c8;
  border: 1px solid #00c6c8;
}

.offcanvas.rounded {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

p#alternate1,
p#alternate2 {
  font-size: 12px;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight {
  border: 1px solid #00c6c8;
  background: #2beaec;
  color: #777620;
  border-radius: 3px;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  border-radius: 3px;
}

.ui-corner-all {
  border-radius: 5px;
}

.filter .form-control {
  min-height: unset;
  padding: unset;
  font-size: 12px;
  border-radius: unset;
  border: transparent;
}

.filter .form-control:focus {
  border-color: unset;
  box-shadow: unset;
}

input#alternate1::placeholder {
  color: #00c6c8;
  font-weight: 500;
}

.filter-filter .category a.btn:hover {
  background-color: #00c6c8;
  border-color: #00c6c8;
  color: white;
}

.category .btn-outline-info:focus {
  background-color: #00c6c8;
  border-color: #00c6c8;
  color: white;
}

.list-denom .d-flex h6 {
  color: #00c6c8;
}

.card a.card-body {
  text-decoration: none;
}

.bub {
  background-color: #f0b83a;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  position: absolute;
  top: 0px;
  margin-right: 85px;
}

.laporanfilter {
  width: 30px;
  margin-left: 600px;
}

.penjualanfilter {
  width: 30px;
  margin-left: 560px;
}
.riwayatfilter {
  width: 30px;
  margin-left: 600px;
}
.rekapfilter {
  width: 30px;
  margin-left: 640px;
}
.ringkasanfilter {
  width: 30px;
  margin-left: 560px;
}

@media only screen and (max-width: 867px) {
  .bub {
    right: 10px;
  }
  .ringkasanfilter {
    width: 25px;
    margin-left: 120px;
  }
  .dashboard {
    width: 50px;
    font-size: 8px;
  }
  .dashboardname {
    font-size: 10px;
  }
  .dashboardfoto {
    width: 50px;
  }
  .dashboardtext {
    font-size: 8px !important;
  }
  .dashboardpage {
    font-size: 13px !important;
  }
  .tanggal {
    width: 110px;
  }
  .laporanfilter {
    width: 25px;
    margin-left: 150px;
  }
  .datapelanggan {
    font-size: 15px;
  }
  .transaksilaporan {
    margin-right: 35px;
    width: 120px;
  }
  .marginbersih {
    margin-right: 40px;
    width: 120px;
  }
  .btnongkir {
    width: 350px;
  }
  .pos {
    width: 0px;
  }
  .penjualanfilter {
    width: 25px;
    margin-left: 210px;
  }
  .riwayatfilter {
    width: 25px;
    margin-left: 150px;
  }
  .rekapfilter {
    width: 25px;
    margin-left: 190px;
  }
  .btnpelanggan {
    width: 350px;
  }
  .keloladata1 {
    width: 180px;
  }
}

@media only screen and (max-width: 1000px) {
  .bub {
    margin-right: 0px;
  }

  .main-beranda .bg-saldo {
    top: unset !important;
    width: 43%;
  }

  .main-beranda .card-body.saldo {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
  }

  .main-beranda .alert-light .btn-close {
    top: 3px;
    padding: 1rem;
  }

  .main-beranda .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
      center/.7em auto no-repeat;
  }

  .img-bg-top {
    background-image: url("/public/img/bg-top.svg");
    background-repeat: no-repeat;
  }

  .main-beranda .navbar-bottom img {
    width: 30px;
  }

  .main-beranda .navbar-bottom a.card {
    text-decoration: none;
    cursor: pointer;
  }

  .main-beranda .container.logo {
    padding-left: 1.5rem;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-10 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-15 {
    font-size: 15px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }

  .font-24 {
    font-size: 24px !important;
  }

  .text-bold {
    font-weight: bold;
  }

  .font-20 {
    font-size: 20px !important;
  }

  .font-32 {
    font-size: 32px !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hr {
    border-top: 1px dashed grey;
    margin: 0.95rem 0 0.5rem;
  }

  .hr-dashed {
    border-top: 2px dashed grey;
    margin: 0.95rem 1.2rem 0.5rem;
  }

  .hr-line {
    border-top: 1px solid #eeeeee;
    margin: 0.95rem 0 0.5rem;
  }

  .hr-product {
    border-top: 1px solid grey;
    margin: 0.95rem 0 0.5rem;
  }

  .riwayat .nav-pills .nav-link {
    border-radius: unset;
  }

  .riwayat .nav-link {
    color: unset;
  }

  .tulisan:hover {
    color: #e5e5e5;
  }

  .riwayat .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: unset !important;
    border-bottom: 2px solid #00c6c8;
    color: #00c6c8;
    box-shadow: 0 0 0 transparent !important;
  }

  .riwayat .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    box-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.075) !important;
  }

  .riwayat .nav-item button {
    font-size: 16px;
    font-weight: 500;
  }

  .riwayat .date-time {
    font-size: 12px;
  }

  .list-denom a:hover {
    color: unset;
  }

  .filter a {
    color: #00c6c8;
  }

  .offcanvas {
    width: 50.8%;
    display: block;
    margin: auto;
  }

  .filter .form-check-input:checked {
    background-color: #00c6c8;
    border-color: #00c6c8;
  }

  .filter .form-check-input {
    width: 1.5em;
    height: 1.5em;
  }

  .filter .offcanvas-bottom.filter-short {
    height: 230px;
  }

  .filter .offcanvas-bottom.filter-filter {
    height: 400px;
  }

  .filter .offcanvas-bottom.filter-product {
    height: 500px;
  }

  .filter .offcanvas-body p.fs-6 {
    color: #00c6c8;
  }

  .filter .offcanvas .btn-primary {
    background-color: #00c6c8;
    border: 1px solid #00c6c8;
  }

  .offcanvas.rounded {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  p#alternate1,
  p#alternate2 {
    font-size: 12px;
  }

  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight {
    border: 1px solid #00c6c8;
    background: #2beaec;
    color: #777620;
    border-radius: 3px;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default {
    border-radius: 3px;
  }

  .ui-corner-all {
    border-radius: 5px;
  }

  .filter .form-control {
    min-height: unset;
    padding: unset;
    font-size: 12px;
    border-radius: unset;
    border: transparent;
  }

  .filter .form-control:focus {
    border-color: unset;
    box-shadow: unset;
  }

  input#alternate1::placeholder {
    color: #00c6c8;
    font-weight: 500;
  }

  .filter-filter .category a.btn:hover {
    background-color: #00c6c8;
    border-color: #00c6c8;
    color: white;
  }

  .category .btn-outline-info:focus {
    background-color: #00c6c8;
    border-color: #00c6c8;
    color: rgb(0, 0, 0);
  }

  .img-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e2e4e7;
    display: inline-block;
    vertical-align: middle;
  }

  .text-grey-sm {
    color: #a0a0a0;
  }

  .border-selected {
    border: 1px solid #00c6c8;
    border-radius: 0.375rem;
  }

  .font-12 {
    font-size: 12px;
  }

  .scroll {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  .horizontal-scrollable-tabs {
    min-height: 50px;
  }

  .horizontal-scrollable-tabs .arrow-right {
    float: right;
  }

  .horizontal-scrollable-tabs .arrow-left {
    float: left;
  }

  .text-gudang:hover {
    color: #eeeeee;
  }

  /* Customize your arrows here */
  .horizontal-scrollable-tabs .scroller {
    font-size: 18px;
    color: red;
    padding: 10px 10px;
    display: none;
  }

  .horizontal-scrollable-tabs .scroller.disabled {
    color: gray;
  }

  .horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
  }

  /* As it has a mobile focus the scrollbar is removed */
  .horizontal-scrollable-tabs
    .horizontal-tabs
    .nav-tabs-horizontal::-webkit-scrollbar {
    width: 0 !important;
  }

  /* It's good to add a min-width so that the items have the same width */
  .horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal > li {
    float: none;
    min-width: 50px;
    text-align: center;
  }

  .nav-tabs-horizontal img {
    height: 30px;
  }

  .nav-tabs-horizontal {
    border-bottom: 0px;
  }

  a {
    border: none !important;
  }
}

.bg-lime {
  background-color: rgba(0, 198, 200, 0.15) !important;
}

.bg-grey {
  background-color: #e5e5e5;
}

.button {
  color: white;
  display: inline-block;
  position: relative;
  padding: 5px 5px;
}

.button__badge {
  background-color: rgba(240, 184, 58, 1);
  border-radius: 50px;
  color: white;
  padding: 1px 6px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10;
}

/* Modal */
.modal-spinner {
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem;
  outline: 0;
  background-color: transparent;
}

.modal-spinner .modal-content {
  background: transparent;
  border: none;
}

.border-primary {
  border: solid 1px #00c6c8 !important;
}

.no-wrap {
  white-space: nowrap !important;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px !important;
  background-color: #e5e5e5;
  overflow-x: hidden !important;
}

/* Games */
.px-cst1 {
  padding-left: 1.9rem;
  padding-right: 1.9rem;
}

.navbar span {
  font-weight: 500;
  font-size: 18px;
}

.content .form-control-lg {
  padding: 0.8rem 1rem;
  font-size: 1rem;
}

.content form {
  padding: 0 1.8rem;
}

.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 5px;
  border-top: unset;
  border-bottom: unset;
  border-radius: 5px !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.owl-carousel .owl-nav button.owl-prev {
  font-size: 30px !important;
  padding-right: 0.5rem !important;
}

.owl-carousel .owl-nav button.owl-next {
  font-size: 30px !important;
  padding-left: 0.5rem !important;
}

.list-game a {
  text-decoration: none;
}

.text-grey {
  color: #454545;
}

@media (max-width: 575.98px) {
  .content.px-5 {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
  }

  .offcanvas {
    width: 100% !important;
  }

  .main-beranda .bg-saldo {
    width: 90% !important;
  }

  .product .card-body {
    font-size: 10px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* Denom Games */
.promo {
  font-size: 10px;
  padding: 1.5px 15px !important;
  border-radius: 0 5px 0 5px;
  background-color: #00c6c8;
}

.gangguan {
  font-size: 10px;
  padding: 1.5px 15px !important;
  border-radius: 0 5px 0 5px;
  background-color: #f0b83a;
}

.align-middle {
  vertical-align: middle;
}

.list-denom .d-flex h6 {
  color: #00c6c8;
}

.card a.card-body {
  text-decoration: none;
}

.main-beranda .bg-saldo {
  top: unset !important;
  width: 43%;
}

.main-beranda .card-body.saldo {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}

.main-beranda .alert-light .btn-close {
  top: 3px;
  padding: 1rem;
}

.main-beranda .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/.7em auto no-repeat;
}

.img-bg-top {
  background-image: url("/public/img/bg-top.svg");
  background-repeat: no-repeat;
}

.main-beranda .navbar-bottom img {
  width: 30px;
}

.main-beranda .navbar-bottom a.card {
  text-decoration: none;
  cursor: pointer;
}

.main-beranda .container.logo {
  padding-left: 1.5rem;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-32 {
  font-size: 32px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.hr {
  border-top: 1px dashed grey;
  margin: 0.95rem 0 0.5rem;
}

.hr-line {
  border-top: 1px solid #eeeeee;
  margin: 0.95rem 0 0.5rem;
}

.riwayat .nav-pills .nav-link {
  border-radius: unset;
}

.riwayat .nav-link {
  color: unset;
}

.riwayat .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: unset !important;
  border-bottom: 2px solid #00c6c8;
  color: #00c6c8;
  box-shadow: 0 0 0 transparent !important;
}

.riwayat .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  box-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.075) !important;
}

.riwayat .nav-item button {
  font-size: 16px;
  font-weight: 500;
}

.riwayat .date-time {
  font-size: 12px;
}

.list-denom a:hover {
  color: unset;
}

.filter a {
  color: #00c6c8;
}

.offcanvas {
  width: 45.8%;
  display: block;
  margin: auto;
}

input[type="check"] {
  display: none;
}

.filter .form-check-input:checked {
  background-color: #00c6c8;
  border-color: #00c6c8;
}

.pengaturan .form-check-input:checked {
  background-color: #00c6c8;
  border-color: #00c6c8;
}

.rincian .form-check-input:checked {
  background-color: #00c6c8;
  border-color: #00c6c8;
}

.deleteform .form-check-input:checked {
  background-color: #f0b83a;
  border-color: #f0b83a;
}

.filter .form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.filter .offcanvas-bottom.filter-short {
  height: 230px;
}

.filter .offcanvas-bottom.filter-filter {
  height: 400px;
}

.filter .offcanvas-bottom.filter-product {
  height: 400px;
}

.filter .offcanvas-bottom.ganti-gudang {
  height: 300px;
}

.rincian .offcanvas-bottom.rincian-product {
  height: 300px;
}

.rincian .offcanvas-bottom.rincian-product {
  width: 760px;
}

.rounded-top.ganti-gudang.offcanvas.offcanvas-bottom.show {
  height: 280px;
}

.rounded-top.rincian-pesanan.offcanvas.offcanvas-bottom.show {
  height: 230px;
}

.rounded-top.detail-product.offcanvas.offcanvas-bottom.show {
  height: 320px;
}

.order .offcanvas-bottom.order-product {
  height: 280px;
}

.order .offcanvas-bottom.order-product {
  width: 760px;
}

.rounded-top.offcanvas.offcanvas-bottom.show {
  height: 280px;
}

.filter .offcanvas-body p.fs-6 {
  color: #00c6c8;
}

.filter .offcanvas .btn-primary {
  background-color: #00c6c8;
  border: 1px solid #00c6c8;
}

.offcanvas.rounded {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

p#alternate1,
p#alternate2 {
  font-size: 12px;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight {
  border: 1px solid #00c6c8;
  background: #2beaec;
  color: #777620;
  border-radius: 3px;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  border-radius: 3px;
}

.ui-corner-all {
  border-radius: 5px;
}

.filter .form-control {
  min-height: unset;
  padding: unset;
  font-size: 12px;
  border-radius: unset;
  border: transparent;
}

.filter .form-control:focus {
  border-color: unset;
  box-shadow: unset;
}

input#alternate1::placeholder {
  color: #00c6c8;
  font-weight: 500;
}

.filter-filter .category a.btn:hover {
  background-color: #00c6c8;
  border-color: #00c6c8;
  color: white;
}

.category .btn-outline-info:focus {
  background-color: #00c6c8;
  border-color: #00c6c8;
  color: white;
}

.list-denom .d-flex h6 {
  color: #00c6c8;
}

.card a.card-body {
  text-decoration: none;
}

.bub {
  background-color: #f0b83a;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  position: absolute;
  top: 0px;
  margin-right: 85px;
}
.detailbtnproduk {
  width: 126px;
}

@media only screen and (max-width: 867px) {
  .bub {
    right: 10px;
  }
  .daftarprodukimg {
    width: 30px;
  }
  .nameproduk {
    font-size: 20px;
    width: 150px;
  }
  .jumlahproduk {
    font-size: 100px !important;
  }
  .detailbtnproduk {
    width: 60px;
  }
  .nilaistok {
    min-width: 50px;
  }
  .titiktiga {
    margin-left: 110px;
  }
}

@media only screen and (max-width: 1000px) {
  .bub {
    margin-right: 0px;
  }

  .main-beranda .bg-saldo {
    top: unset !important;
    width: 43%;
  }

  .main-beranda .card-body.saldo {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
  }

  .main-beranda .alert-light .btn-close {
    top: 3px;
    padding: 1rem;
  }

  .main-beranda .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
      center/.7em auto no-repeat;
  }

  .img-bg-top {
    background-image: url("/public/img/bg-top.svg");
    background-repeat: no-repeat;
  }

  .main-beranda .navbar-bottom img {
    width: 30px;
  }

  .main-beranda .navbar-bottom a.card {
    text-decoration: none;
    cursor: pointer;
  }

  .main-beranda .container.logo {
    padding-left: 1.5rem;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-10 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-15 {
    font-size: 15px !important;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-24 {
    font-size: 24px !important;
  }

  .text-bold {
    font-weight: bold;
  }

  .font-20 {
    font-size: 20px !important;
  }

  .font-32 {
    font-size: 32px !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hr {
    border-top: 1px dashed grey;
    margin: 0.95rem 0 0.5rem;
  }

  .hr-dashed {
    border-top: 2px dashed grey;
    margin: 0.95rem 1.2rem 0.5rem;
  }

  .hr-line {
    border-top: 1px solid #eeeeee;
    margin: 0.95rem 0 0.5rem;
  }

  .hr-product {
    border-top: 1px solid grey;
    margin: 0.95rem 0 0.5rem;
  }

  .riwayat .nav-pills .nav-link {
    border-radius: unset;
  }

  .riwayat .nav-link {
    color: unset;
  }

  .tulisan:hover {
    color: #e5e5e5;
  }

  .riwayat .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: unset !important;
    border-bottom: 2px solid #00c6c8;
    color: #00c6c8;
    box-shadow: 0 0 0 transparent !important;
  }

  .riwayat .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    box-shadow: 0 0.125rem 0 rgba(0, 0, 0, 0.075) !important;
  }

  .riwayat .nav-item button {
    font-size: 16px;
    font-weight: 500;
  }

  .riwayat .date-time {
    font-size: 12px;
  }

  .list-denom a:hover {
    color: unset;
  }

  .filter a {
    color: #00c6c8;
  }

  .offcanvas {
    width: 50.8%;
    display: block;
    margin: auto;
  }

  .filter .form-check-input:checked {
    background-color: #00c6c8;
    border-color: #00c6c8;
  }

  .filter .form-check-input {
    width: 1.5em;
    height: 1.5em;
  }

  .filter .offcanvas-bottom.filter-short {
    height: 230px;
  }

  .filter .offcanvas-bottom.filter-filter {
    height: 400px;
  }

  .filter .offcanvas-bottom.filter-product {
    height: 500px;
  }

  .filter .offcanvas-body p.fs-6 {
    color: #00c6c8;
  }

  .filter .offcanvas .btn-primary {
    background-color: #00c6c8;
    border: 1px solid #00c6c8;
  }

  .offcanvas.rounded {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  p#alternate1,
  p#alternate2 {
    font-size: 12px;
  }

  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight {
    border: 1px solid #00c6c8;
    background: #2beaec;
    color: #777620;
    border-radius: 3px;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default {
    border-radius: 3px;
  }

  .ui-corner-all {
    border-radius: 5px;
  }

  .filter .form-control {
    min-height: unset;
    padding: unset;
    font-size: 12px;
    border-radius: unset;
    border: transparent;
  }

  .filter .form-control:focus {
    border-color: unset;
    box-shadow: unset;
  }

  input#alternate1::placeholder {
    color: #00c6c8;
    font-weight: 500;
  }

  .filter-filter .category a.btn:hover {
    background-color: #00c6c8;
    border-color: #00c6c8;
    color: white;
  }

  .category .btn-outline-info:focus {
    background-color: #00c6c8;
    border-color: #00c6c8;
    color: rgb(0, 0, 0);
  }

  .img-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e2e4e7;
    display: inline-block;
    vertical-align: middle;
  }

  .text-grey-sm {
    color: #a0a0a0;
  }

  .border-selected {
    border: 1px solid #00c6c8;
    border-radius: 0.375rem;
  }

  .font-12 {
    font-size: 12px;
  }

  .scroll {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  .horizontal-scrollable-tabs {
    min-height: 50px;
  }

  .horizontal-scrollable-tabs .arrow-right {
    float: right;
  }

  .horizontal-scrollable-tabs .arrow-left {
    float: left;
  }

  .text-gudang:hover {
    color: #eeeeee;
  }

  /* Customize your arrows here */
  .horizontal-scrollable-tabs .scroller {
    font-size: 18px;
    color: red;
    padding: 10px 10px;
    display: none;
  }

  .horizontal-scrollable-tabs .scroller.disabled {
    color: gray;
  }

  .horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
  }

  /* As it has a mobile focus the scrollbar is removed */
  .horizontal-scrollable-tabs
    .horizontal-tabs
    .nav-tabs-horizontal::-webkit-scrollbar {
    width: 0 !important;
  }

  /* It's good to add a min-width so that the items have the same width */
  .horizontal-scrollable-tabs .horizontal-tabs .nav-tabs-horizontal > li {
    float: none;
    min-width: 50px;
    text-align: center;
  }

  .nav-tabs-horizontal img {
    height: 30px;
  }

  .nav-tabs-horizontal {
    border-bottom: 0px;
  }

  a {
    border: none !important;
  }
}

.bg-lime {
  background-color: rgba(0, 198, 200, 0.15) !important;
}

.bg-grey {
  background-color: #e5e5e5;
}

.button {
  color: white;
  display: inline-block;
  position: relative;
  padding: 5px 5px;
}

.button__badge {
  background-color: rgba(240, 184, 58, 1);
  border-radius: 50px;
  color: white;
  padding: 1px 6px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10;
}

/* Modal */
.modal-spinner {
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem;
  outline: 0;
  background-color: transparent;
}

.modal-spinner .modal-content {
  background: transparent;
  border: none;
}

.border-primary {
  border: solid 1px #00c6c8 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.bg-grey {
  background-color: #e5e5e5;
}

.button {
  color: white;
  display: inline-block;
  position: relative;
  padding: 5px 5px;
}

.button__badge {
  background-color: rgba(240, 184, 58, 1);
  border-radius: 50px;
  color: white;
  padding: 1px 6px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10;
}

/* Modal */
.modal-spinner {
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem;
  outline: 0;
  background-color: transparent;
}

.modal-spinner .modal-content {
  background: transparent;
  border: none;
}

.border-primary {
  border: solid 1px #00c6c8 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.form-check-input:checked {
  background-color: #00c6c8 !important;
  border-color: #00c6c8 !important;
}

div#order {
  height: 320px;
}

/* Print */
@media print and (color) {
  * {
     -webkit-print-color-adjust: exact;
     print-color-adjust: exact;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  body {
    color: #000;
    background: #fff;
    margin: 0;
 }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  @page { 
    color: #000;
    background: #fff;
    margin-left: -15px;
    margin-top: -15px;
    left: 0;
    top: 0;
    width: 58mm;
    height: 210mm;
  }

  #section-to-print {
    margin: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 65mm;
    height: 210mm;
  }
}


.border-bottom {
  border-bottom: dashed 3px #000 !important;
}

div[highcharts-container ] {
  height: 100% !important;
  width: 100%;
}